<template>
	<div class="login-page" v-title data-title="三特科技信息管理系统">
		<div class="login-page-bg">
			<h1>请登录</h1>
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item prop="userName">
					<el-input placeholder="请录入用户名" v-model="form.userName"></el-input>
				</el-form-item>
				<el-form-item prop="passWord">
					<el-input placeholder="请录入密码" type="password" v-model="form.passWord"></el-input>
				</el-form-item>
				<el-form-item style="font-size: 12px;">
					<el-button style="width: 100%;font-size: 16px;" type="primary" :loading="loading" @click="submit">
						立即登录</el-button>
					建议使用chrome、firefox浏览器,©copyright2022
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Login",
		data() {
			return {
				form: {
					userName: '周晓英',
					passWord: '123456zxy',
				},
				loading: false,
				rules: {
					userName: [{
						required: true,
						message: '请录入用户名'
					}],
					passWord: [{
						required: true,
						message: '请录入登录密码'
					}],
				}
			}
		},
		methods: {
			submit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						// console.log(this.form)
						this.loading = true
						//执行登录操作
						this.$store.dispatch('login', this.form).then(() => {
							setTimeout(()=>{
								//执行页面跳转
								this.$router.push('/admin/index')
							}, 500);
							this.$notify({
								type: 'success',
								title: '登录成功',
								duration: 5000,
								message: '欢迎'+this.form.userName+'回来！',	
							})
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.login-page {
		height: 100%;
		background: url("../../assets/aImage/login-bg.jpg") no-repeat;
		background-size: cover;
	}

	.login-page-bg {
		position: absolute;
		top: 25%;
		left: 30%;
		right: 30%;
		bottom: 30%;
		background-color: #ffffff;
		padding-top: 20px;
		text-align: center;
		color: #444444;
		padding-right: 50px;
		border-radius: 10px;
		-webkit-box-shadow: #666 0px 0px 10px;
		-moz-box-shadow: #666 0px 0px 10px;
		box-shadow: #666 0px 0px 10px;

	}

	.login-page-bg h1 {
		margin-bottom: 20px;
	}
</style>
